import {
	isBimifyReseller,
	isLoggedUserAdmin,
	isLoggedUserBO,
} from "../../utils/permission/user";

export const canCreatePackage = () =>
	isBimifyReseller() && (isLoggedUserAdmin() || isLoggedUserBO());

// (isBimifyAdmin() ||
// (isLoggedUserAdmin() &&
//     tokenParams()?.company?.id === companyId)
export const canEditPackage = () => canCreatePackage();

export const canOrderFromPackage = () =>
	!(isLoggedUserAdmin() || isLoggedUserBO());
